import classNames from 'clsx'

export const LogoImage: React.FCC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      width={276}
      className={classNames(`w-[90px] sm:w-[100px] fill-current`, className)}
      viewBox="0 0 276 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6569 0C14.621 0 0 14.7746 0 33C0 51.2254 14.621 66 32.6569 66C40.693 66 48.0543 63.0647 53.7426 58.2001L60.2534 63.1345C66.9651 68.2212 76.597 65.9235 80.3489 58.3408L86.1641 46.5882H112.446L106.858 34.9412H91.9271L101.813 14.9621L127.066 66H139.83L110.404 6.2809C106.864 -0.872803 96.7614 -0.872822 93.2218 6.28089L70.0397 53.132C69.5038 54.2153 68.1278 54.5435 67.169 53.8169L61.1032 49.2197C63.784 44.4285 65.3138 38.8924 65.3138 33C65.3138 14.7746 50.6928 0 32.6569 0ZM11.526 33C11.526 21.2071 20.9866 11.6471 32.6569 11.6471C44.3272 11.6471 53.7879 21.2071 53.7879 33C53.7879 36.2728 53.0604 39.3701 51.7606 42.1392L42.2619 34.9403L35.3463 44.2579L44.144 50.9256C40.8374 53.0943 36.8943 54.3529 32.6569 54.3529C20.9866 54.3529 11.526 44.7929 11.526 33Z"
      />
      <path d="M161.528 37.1947H167.799V32.9707H161.528V20.944H156.71V32.9707H151.252V37.1947H156.71V60.2507C156.71 62.2453 157.155 63.712 158.045 64.6507C158.935 65.5502 160.309 66 162.167 66C163.135 66 164.547 65.8436 166.405 65.5307V61.3653H163.618C162.69 61.3653 162.109 61.2871 161.877 61.1307C161.645 60.9351 161.528 60.4267 161.528 59.6053V37.1947Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.325 49.808H204.076C203.96 44.3324 202.354 40.0302 199.258 36.9013C196.161 33.7333 192.31 32.1493 187.704 32.1493C183.098 32.1493 179.228 33.8116 176.093 37.136C172.997 40.4604 171.448 44.4498 171.448 49.104C171.448 53.7582 172.997 57.7475 176.093 61.072C179.189 64.3573 183.118 66 187.878 66C191.323 66 194.284 65.1982 196.761 63.5947C199.238 61.952 201.386 59.4293 203.205 56.0267L199.141 53.9147C196.471 58.96 192.6 61.4827 187.53 61.4827C184.55 61.4827 181.976 60.4267 179.809 58.3147C177.68 56.1635 176.519 53.328 176.325 49.808ZM194.787 39.1307C196.839 40.656 198.155 42.7875 198.735 45.5253H176.906C178.686 39.7369 182.286 36.8427 187.704 36.8427C190.375 36.8427 192.736 37.6053 194.787 39.1307Z"
      />
      <path d="M241.604 58.432C239.939 60.896 237.753 62.7733 235.043 64.064C232.373 65.3547 229.47 66 226.335 66C221.458 66 217.375 64.3769 214.085 61.1307C210.834 57.8844 209.208 53.9147 209.208 49.2213C209.208 44.528 210.853 40.5191 214.143 37.1947C217.433 33.8311 221.69 32.1493 226.915 32.1493C230.012 32.1493 232.856 32.8338 235.45 34.2027C238.082 35.5715 240.133 37.488 241.604 39.952L237.83 42.3573C235.005 38.6418 231.308 36.784 226.741 36.784C223.142 36.784 220.123 37.9573 217.684 40.304C215.246 42.6115 214.027 45.4862 214.027 48.928C214.027 52.3698 215.227 55.3227 217.626 57.7867C220.026 60.2507 223.064 61.4827 226.741 61.4827C231.153 61.4827 234.869 59.6053 237.888 55.8507L241.604 58.432Z" />
      <path d="M271.181 64.944H276V48.5173C276 44.176 275.536 40.9689 274.607 38.896C273.678 36.784 272.284 35.1413 270.427 33.968C268.569 32.7555 266.498 32.1493 264.214 32.1493C259.918 32.1493 256.203 34.0267 253.068 37.7813V20.944H248.249V64.944H253.068V53.0933C253.068 48.8693 253.242 46.0533 253.59 44.6453C253.977 43.2373 254.654 41.9075 255.622 40.656C256.59 39.4044 257.751 38.4267 259.105 37.7227C260.46 37.0187 261.873 36.6667 263.344 36.6667C264.853 36.6667 266.13 36.96 267.175 37.5467C268.259 38.0942 269.111 38.9156 269.73 40.0107C270.349 41.0667 270.736 42.2009 270.891 43.4133C271.085 44.6258 271.181 46.7182 271.181 49.6907V64.944Z" />
      <path d="M151.729 61.875C151.729 64.1532 149.91 66 147.665 66C145.42 66 143.601 64.1532 143.601 61.875C143.601 59.5968 145.42 57.75 147.665 57.75C149.91 57.75 151.729 59.5968 151.729 61.875Z" />
    </svg>
  )
}
